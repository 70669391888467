'use client'

import { Button } from '@noissue-ui-kit/button'
import Link from 'next/link'
import { useEffect } from 'react'
import BaseErrorState from './(main)/_components/ErrorState/BaseErrorState'
import * as Sentry from '@sentry/nextjs'

export default function Error(error: Error) {
  useEffect(() => {
    Sentry.captureException(error, {
      contexts: {
        location: {
          module: 'Root Platform Error Boundary',
        },
      },
    })
  }, [error])

  return (
    <BaseErrorState message={error?.message}>
      <Link href="/">
        <Button size={'m'} variant="primary" corners={'rounded'}>
          Homepage
        </Button>
      </Link>
    </BaseErrorState>
  )
}
