import { Button } from '@noissue-ui-kit/button'
import Link from 'next/link'
import { ReactNode } from 'react'

export default function BaseErrorState({
  message,
  children,
}: {
  message: string
  children: ReactNode
}) {
  return (
    <div className="w-full h-full min-h-[calc(100vh-200px)] flex flex-col items-center justify-center min-w-full bg-black-10 text-6xl">
      <h1 className="mb-8 text-5xl font-mori font-semibold md:text-7xl text-black-80">
        {message || 'Something went wrong!'}
      </h1>

      <p className="text-3xl text-black-80 mt-2 mb-16 mx-auto">
        Please try again or{' '}
        <Link href="mailto:support@noissue.com" className="text-pitaya">
          contact us
        </Link>{' '}
        if the problem persists.
      </p>
      {children}
    </div>
  )
}
